#cost-voiceArea {
  padding-top: 0;
  .cost-voiceGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    border-top: solid 1px #7c7b69;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    .costArea {
      padding: 80px 0;
      border-bottom: solid 1px #7c7b69;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 20px;
      @include media-breakpoint-up(sm) {
        border-bottom: none;
        border-right: solid 1px #7c7b69;
        padding: 50px 20px 0 0;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        flex-basis: 50%;
      }
      .costImg {
        @include media-breakpoint-up(sm) {
          flex-basis: 50%;
        }
        picture {
          img {
          }
        }
      }
      .costContent {
        @include media-breakpoint-up(sm) {
          position: relative;
          width: 270px;
          height: 100%;
        }
        .midashi1 {
          .en {
            letter-spacing: -0.05em;
            @include media-breakpoint-up(sm) {
              font-size: 45px;
            }
          }
        }
        h3 {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .costCont {
          font-weight: bold;
          color: $body-color;
          margin-bottom: 50px;
          @include media-breakpoint-up(sm) {
            margin-bottom: 70px;
          }
        }
        .moreBtn {
          @include media-breakpoint-up(sm) {
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    .voiceArea {
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 20px;
      @include media-breakpoint-up(sm) {
        border-bottom: none;
        padding: 50px 0 0 20px;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        flex: 1;
      }
      .voiceImg {
        @include media-breakpoint-up(sm) {
          flex-basis: 50%;
        }
        picture {
          img {
          }
        }
      }
      .voiceContent {
        @include media-breakpoint-up(sm) {
          position: relative;
          width: 270px;
          height: 100%;
        }
        .midashi1 {
          .en {
            @include media-breakpoint-up(sm) {
              font-size: 60px;
            }
          }
        }
        h3 {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .voiceCont {
          font-weight: bold;
          color: $body-color;
          margin-bottom: 50px;
          @include media-breakpoint-up(sm) {
            margin-bottom: 70px;
          }
        }
        .moreBtn {
          @include media-breakpoint-up(sm) {
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}
