.formTableGroup {
  .formTable {
    width: 100%;
    @include media-breakpoint-up(sm) {
      display: table;
    }
    dl {
      display: block;
      margin: 0;
      padding: 0;
      @include media-breakpoint-up(sm) {
        display: table-row;
      }
      dt {
        display: block;
        font-weight: bold;
        text-align: left;
        padding: 15px 0;
        margin: 0;
        @include media-breakpoint-up(sm) {
          display: table-cell;
          padding: 23px 0;
          width: 1%;
          white-space: nowrap;
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin: 0;
          @include media-breakpoint-up(sm) {
            justify-content: space-between;
            gap: 25px;
          }
          span {
            color: $white;
            font-weight: 300;
            font-size: 13px;
            padding: 1px 8px;
          }
        }
        .badge {
          padding: 10px 15px;
        }
      }
      dd {
        padding: 0 0 15px;
        margin: 0;
        white-space: nowrap;
        @include media-breakpoint-up(sm) {
          padding: 15px 0 15px 60px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .form-control,
  .form-select,
  .custom-select {
    position: relative;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 13px 10px;
    @include media-breakpoint-up(sm) {
      padding: 18px 15px;
    }
    &.short {
      width: 40%;
      @include media-breakpoint-up(sm) {
        width: 20%;
      }
    }
    &.middle {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 60%;
      }
    }
    &.select {
      background-image: url(../img/arrow-down.svg);
      background-position: right 10px center;
      background-repeat: no-repeat;
    }
    &.calendar {
      background-image: url(../img/calendar.svg);
      background-position: right 10px center;
      background-repeat: no-repeat;
    }
  }
}
.select {
  color: #808080;
}
