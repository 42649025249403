.faq {
  width: 100%;
  @include media-breakpoint-up(sm) {
    flex: 1;
  }
  .faqSingle {
    border-bottom: solid 0.5px #7c7b69;
    font-weight: bold;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      text-decoration: none;
      width: 100%;
      padding: 10px 0;
      gap: 10px;
      .icon {
        font-size: 20px;
        font-weight: bold;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #214b3d;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin: 0;
      }
      &.collapsed {
        &::after {
          transform: rotate(0);
          font-size: 15px;
          font-weight: normal;
          align-self: center;
          margin-left: 15px;
          margin-right: 15px;
          font-family: "Font Awesome 5 Pro";
          content: "\f078";
          color: #333;
          @include media-breakpoint-up(sm) {
            margin-left: 33px;
            margin-right: 33px;
          }
        }
      }
      &::after {
        transform: rotate(180deg);
        font-size: 15px;
        font-weight: normal;
        align-self: center;
        margin-left: 15px;
        margin-right: 15px;
        font-family: "Font Awesome 5 Pro";
        content: "\f078";
        color: #333;
        @include media-breakpoint-up(sm) {
          margin-left: 33px;
          margin-right: 33px;
        }
      }
    }
    .body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-decoration: none;
      width: 100%;
      padding: 0 0 10px;
      gap: 10px;
      .icon {
        font-size: 20px;
        font-weight: bold;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #333;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cont {
        flex: 1;
        p {
          margin: 0;
          color: #333;
          font-weight: normal;
        }
      }
    }
    &:last-child {
      border: none;
    }
  }
}
