#footer {
  background-color: $body-color;
  padding: 50px 0 20px;
  color: $white;
  position: relative;
  .topBtn {
    position: absolute;
    top: 10px;
    right: 6vw;
    @include media-breakpoint-up(lg) {
      right: 10vw;
    }
    img {
    }
  }
  .footerGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    margin-bottom: 50px;
    font-weight: bold;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .left {
      .logo {
        img {
        }
      }
      .logoname {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .address {
        margin-bottom: 50px;
      }
      .tel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-size: 25px;
        i {
        }
        .telnumber {
          margin: 0;
          & a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
    .right {
      @include media-breakpoint-up(lg) {
        margin-top: 50px;
      }
      .gnav {
        color: $white;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 30px;
        padding: 0;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 25px;
          font-size: 1.5vw;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1vw;
        }
        li {
          a {
            color: $white;
            text-decoration: none;
            white-space: nowrap;
          }
        }
      }
      .sns {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        height: 20px;
        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
          align-items: center;
        }
        a {
          i {
            color: $white;
          }
        }
      }
    }
  }
  .copyright {
    margin: 0;
    text-align: center;
    font-size: 13px;
  }
}
