.floatingMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex-sticky;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @include media-breakpoint-up(xl) {
    top: 30%;
    bottom: auto;
    left: auto;
    right: 0;
    transform: translateY(-30%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: auto;
  }
  .floatBtn {
    width: 50%;
    background-color: #c65840;
    border: solid 2px $white;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    color: $white;
    padding: 10px 0;
    transition: $transition-base;
    @include media-breakpoint-up(xl) {
      width: 100%;
      writing-mode: vertical-rl;
      padding: 40px 10px;
    }
    &:hover {
      background-color: $body-color;
    }
  }
}
