.modal-content {
  background-image: url(../img/background-sm.png);
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  @include media-breakpoint-up(sm) {
    background-image: url(../img/background.png);
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    padding: 30px 6vw;
    @include media-breakpoint-up(lg) {
      padding: 50px 10vw;
    }
    #modalArea {
      .eventtitle {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 40px;
        }
      }
      .uketsuke {
        background-color: #ff6939;
        color: $white;
        width: 150px;
        padding: 8px 50px;
        margin: 0 auto 20px;
        @include media-breakpoint-up(sm) {
          width: 150px;
        }
      }
      .mainImg {
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 30px;
        }
        picture {
          img {
          }
        }
      }
      .eventArea {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 50px;
        }
        dt {
          width: 30%;
          font-weight: bold;
          border-bottom: solid 1px #7c7b69;
          padding: 10px 15px;
          &:last-of-type {
            border: none;
          }
        }
        dd {
          width: 70%;
          border-bottom: solid 1px #7c7b69;
          padding: 10px 15px;
          margin: 0;
          div {
            margin-bottom: 20px;
          }
          .eventImg {
            max-width: 100%;
            img {
              width: 100%;
            }
          }
          &:last-of-type {
            border: none;
          }
        }
      }
      .eventBtn {
        text-decoration: none;
        background-color: $primary;
        color: #fff;
        width: 250px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transition: $transition-base;
        &:hover{
          background-color: $body-color;
        }
      }
    }
  }
}
