.eventform {
  .midashi1 {
  }
  .body {
    .formTableGroup {
      .formTable {
      }
      .privacyCont {
        width: 100%;
        height: 250px;
        background-color: $white;
        padding: 15px;
        margin-bottom: 50px;
        overflow: auto;
        @include media-breakpoint-up(sm) {
          margin-bottom: 100px;
        }
        p {
          margin: 0;
        }
      }
      .eventformBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #c65840;
        text-decoration: none;
        position: relative;
        transition: $transition-base;
        width: 280px;
        padding: 20px 0;
        margin: 0 auto;
        color: $white;
        border: none;
        &::after {
          font-family: "Font Awesome 5 Pro";
          content: "\f054";
          color: $white;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: $body-color;
        }
      }
    }
  }
}
