.moreBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  color: $body-color;
  width: 250px;
  height: 50px;
  margin: 0 auto 7px;
  border: solid 2px $body-color;
  position: relative;
  font-weight: bold;
  transition: $transition-base;
  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }
  .fa-arrow-right {
    position: absolute;
    right: 15px;
    color: $body-color;
    transition: $transition-base;
  }
  &::after {
    content: "";
    border-bottom: 2px solid $body-color;
    border-right: 2px solid $body-color;
    position: absolute;
    top: 7px;
    right: -7px;
    width: 100%;
    height: 100%;
  }
  &.center {
    @include media-breakpoint-up(sm) {
      margin: 0 auto 7px;
    }
  }
  &:hover {
    color: $white;
    background-color: $body-color;
    .fa-arrow-right {
      color: $white;
    }
  }
}
.backBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  color: $body-color;
  width: 250px;
  height: 50px;
  margin: 0 auto 7px;
  border: solid 2px $body-color;
  position: relative;
  font-weight: bold;
  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }
  .fa-arrow-left {
    position: absolute;
    left: 10px;
    color: $body-color;
  }
  &::after {
    content: "";
    border-bottom: 2px solid $body-color;
    border-right: 2px solid $body-color;
    position: absolute;
    top: 7px;
    right: -7px;
    width: 100%;
    height: 100%;
  }
  &.center {
    @include media-breakpoint-up(sm) {
      margin: 0 auto 7px;
    }
  }
  &:hover {
    color: $white;
    background-color: $body-color;
    .fa-arrow-left {
      color: $white;
    }
  }
}
