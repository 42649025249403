#heroArea {
  position: relative;
  background: #fff url(../img/loader.gif) no-repeat center center;
  z-index: 1;
  margin-top: -$headerHeight;
  @include media-breakpoint-up(sm) {
    margin-top: -$headerHeightSM;
  }
  .video-bg {
    @include media-breakpoint-up(lg) {
      position: relative;
      max-width: 100vw;
      height: 100vh;
      overflow: hidden;
    }
    video {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .spVideo {
      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }
    .pcVideo {
      display: none !important;
      @include media-breakpoint-up(lg) {
        display: block !important;
      }
    }
  }
  .heroText {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-left: 6vw;
    font-weight: bold;
    color: $white;
    @include media-breakpoint-up(lg) {
      margin-left: 10vw;
    }
    .logo {
      width: 80%;
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
      img {
        width: 200%;
      }
    }
    .subTitle {
      margin-bottom: 40px;
      font-size: 20px;
      @include media-breakpoint-up(sm) {
        font-size: 40px;
      }
    }
    .subText {
      margin: 0;
    }
  }
  .request {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 6vw;
    background-color: #f6d845;
    text-decoration: none;
    color: $black;
    transition: $transition-base;
    @include media-breakpoint-up(sm) {
      padding: 18px 60px 18px 10vw;
    }
    i {
    }
    .info {
      margin: 0;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
    }
    &:hover {
      background-color: $body-color;
      color: $white;
    }
  }
}
