.midashi1 {
  margin-bottom: 30px;
  color: $primary;
  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
  }
  .en {
    font-family: capitolium-2, serif;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 5px;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      font-size: 80px;
    }
  }
  .ja {
    margin: 0;
    font-weight: bold;
  }
  &.center {
    text-align: center;
  }
}
