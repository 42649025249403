.banner {
  position: fixed;
  z-index: 99998;
  bottom: 0;
  right: 0;
  margin: 0 15px 60px;
  opacity: 0;
  transition: opacity 0.5s ease;
  @include media-breakpoint-up(sm) {
    opacity: 1;
  }
  @include media-breakpoint-up(xl) {
    right: 0;
    margin: 15px 20px;
  }
  .banner-close {
    font-weight: bold;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 99999;
    border: none;
    background-color: #f8f8f8;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    color: $body-color;
    padding: 0;
    @include media-breakpoint-up(sm) {
      top: -5px;
      right: -4px;
    }
  }
  a {
    text-decoration: none;
    transition: $transition-base;
    .img {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 300px;
      }
    }
  }
  &.show {
    opacity: 1;
  }
}
