#photogallaeryArea {
  padding: 80px 6vw;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 120px 10vw;
  }

  .white {
    background-color: rgba($color: $white, $alpha: 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      width: 83%;
    }
  }
  .photoGalleryGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
      justify-content: center;
      align-items: stretch;
      gap: 50px;
    }
    .photoGalleryRight {
      @include media-breakpoint-up(lg) {
        flex-basis: 62%;
      }
      img {
        width: 100%;
      }
    }
    .photoGalleryLeft {
      @include media-breakpoint-up(sm) {
        flex: 1;
        position: relative;
      }
      .midashi1 {
      }
      h3 {
        color: $body-color;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 50px;
        }
      }
      .photoGalleryCont {
        color: $body-color;
        font-weight: bold;
        margin-bottom: 50px;
      }
      .moreBtn {
      }
    }
  }
}
