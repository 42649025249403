.widelayout {
  padding-top: 80px;
  padding-bottom: 80px;
  @include media-breakpoint-up(lg) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.brandContainer {
  margin-left: 6vw;
  margin-right: 6vw;
  @include media-breakpoint-up(lg) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(1000px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineCont {
  max-width: 1200px;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .mainCont {
      width: 800px;
    }
    .sideCont {
      width: 320px;
    }
  }
}
.bg-gray {
  background-color: $gray-100;
}
