$headerHeight: 60px;
$headerHeightSM: 80px;
#topheader {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  transition: $transition-base;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 6vw;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
    gap: 50px;
  }
  .logo {
    width: 40%;
    margin: 0;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
    .noscroll {
      img {
        display: block;
      }
    }
    .scroll {
      display: none;
      img {
      }
    }
  }
  .gnav {
    display: none;
    color: $white;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      font-size: 1vw;
    }
    li {
      a {
        font-weight: bold;
        color: $white;
        text-decoration: none;
        transition: $transition-base;
        white-space: nowrap;
        &:hover {
          color: $body-color;
        }
      }
    }
  }
  .sns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-right: calc(60px - 6vw);
    @include media-breakpoint-up(lg) {
      gap: 20px;
    }
    a {
      transition: $transition-base;
      text-decoration: none;
      text-align: center;
      width: 35px;
      @include media-breakpoint-up(lg) {
        width: 55px;
      }
      .noscroll {
      }
      .scroll {
        display: none;
      }
      span {
        display: none;
        @include media-breakpoint-up(lg) {
          color: $white;
          font-size: 10px;
          display: block;
          letter-spacing: 0.05em;
          white-space: nowrap;
        }
      }
      &:hover {
        .noscroll {
          display: none;
        }
        .scroll {
          display: inline-block;
        }
        span {
          color: $body-color;
        }
      }
    }
  }
  &.scrolled {
    .logo {
      .noscroll {
        display: none;
      }
      .scroll {
        display: block;
      }
    }
    .gnav {
      li {
        a {
          color: $body-color;
          &:hover {
            color: $white;
          }
        }
      }
    }
    .sns {
      a {
        .noscroll {
          display: none;
        }
        .scroll {
          display: inline-block;
        }
        span {
          color: $body-color;
        }
        &:hover {
          .noscroll {
            display: inline-block;
          }
          .scroll {
            display: none;
          }
          span {
            color: $white;
          }
        }
      }
    }
  }
}
#newheader {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  transition: $transition-base;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 6vw;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
    gap: 50px;
  }
  .logo {
    width: 40%;
    margin: 0;
    @include media-breakpoint-up(sm) {
      width: auto;
    }
    .scroll {
      img {
        display: block;
      }
    }
  }
  .gnav {
    display: none;
    color: $white;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      font-size: 1vw;
    }
    li {
      a {
        font-weight: bold;
        color: $body-color;
        text-decoration: none;
        transition: $transition-base;
        white-space: nowrap;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .sns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-right: calc(60px - 6vw);
    @include media-breakpoint-up(lg) {
      gap: 20px;
    }
    a {
      transition: $transition-base;
      text-decoration: none;
      text-align: center;
      width: 35px;
      @include media-breakpoint-up(lg) {
        width: 55px;
      }
      span {
        display: none;
        @include media-breakpoint-up(lg) {
          color: $body-color;
          font-size: 10px;
          display: block;
          letter-spacing: 0.05em;
          white-space: nowrap;
        }
      }
      &:hover {
        span {
          color: $primary;
        }
      }
    }
  }
}
