#styleArea {
  border-bottom: solid 1px #7c7b69;
  .midashi1 {
  }
  .areaGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    img {
      border: 3px solid $white;
    }
    .styleTitle {
      text-align: center;
      padding: 15px 0;
      border-top: solid 2px #7c7b69;
      border-bottom: solid 2px #7c7b69;
      width: 100%;
      .en {
        font-family: capitolium-2, serif;
        font-weight: bold;
        font-size: 35px;
        margin: 0;
        letter-spacing: -0.01em;
        line-height: 1em;
        .md-only {
          display: none;
          @include media-breakpoint-up(sm) {
            display: block;
          }
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
      .ja {
        font-size: 13px;
        font-weight: bold;
        margin: 0;
      }
    }
    .ButtonBox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      @include media-breakpoint-up(sm) {
        flex-direction: column;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
      .areaButton {
        text-decoration: none;
        width: 150px;
        color: $white;
        text-align: center;
        font-weight: bold;
        padding: 10px 0;
        background-color: $primary;
        transition: $transition-base;
        &:hover {
          background-color: $body-color;
        }
      }
    }
  }
}
