#worksArea {
  .workGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    @include media-breakpoint-up(sm) {
      flex-direction: column-reverse;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
    }
    .text {
      margin-left: 6vw;
      margin-right: 6vw;
      @include media-breakpoint-up(sm) {
        width: auto;
        margin-left: 10vw;
        margin-right: 0;
      }
      .midashi1 {
      }
      h3 {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
          font-size: 40px;
          margin-bottom: 50px;
        }
      }
      .workText {
        font-weight: bold;
        margin-bottom: 50px;
      }
      .moreBtn {
      }
    }
    .workswiper {
      width: 100%;
      position: relative !important;
      background-color: rgba($color: $white, $alpha: 0.4);
      padding: 50px 6vw;
      @include media-breakpoint-up(lg) {
        width: 60vw;
        padding: 120px 10vw 120px 25px;
      }
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px !important;
        @include media-breakpoint-up(lg) {
          padding-right: 10vw;
          bottom: 80px !important;
        }
        .swiper-pagination-bullet {
          background-color: $white;
          border: solid 0.5px $body-color;
          opacity: 1;
          &.swiper-pagination-bullet-active {
            background-color: $body-color;
          }
        }
      }
    }
  }
}
