.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed;
  min-width: $headerHeight;
  min-height: $headerHeight;
  @include media-breakpoint-up(sm) {
    min-width: $headerHeightSM;
    min-height: $headerHeightSM;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  &.scrolled {
    .toggleMenuBtn {
      .bar,
      &::before,
      &::after {
        background-color: $body-color;
      }
    }
    .menu {
      color: $body-color;
    }
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeight;
    height: $headerHeight;
    display: flex;
    gap: 8%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include media-breakpoint-up(sm) {
      width: $headerHeightSM;
      height: $headerHeightSM;
    }
    .bar,
    &::before,
    &::after {
      width: 50%;
      height: 2px;
      background-color: $white;
      content: "";
      transition: $transition-base;
    }

    &:hover {
      &::after {
        transform: scaleX(100%);
      }
    }
    &.active {
      .bar {
        opacity: 0;
        display: none;
        position: absolute;
        background-color: $body-color;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: rotate(45deg);
        transform-origin: center;
        background-color: $body-color;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 25%;
        transform: rotate(-45deg);
        transform-origin: center;
        background-color: $body-color;
      }
    }
  }
  .menu {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    width: $headerHeight;
    margin-top: calc($headerHeight - 20px);
    margin-bottom: 0;
    font-size: 5px;
    font-weight: bold;
    color: $white;
    text-align: center;
    @include media-breakpoint-up(sm) {
      width: $headerHeightSM;
      margin-top: calc($headerHeightSM - 30px);
    }
  }
  .cont {
    padding-top: $headerHeight;
    position: relative;
    z-index: 1;
    width: 600px;
    height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    background-image: url(../img/background-sm.png);
    background-repeat: repeat;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightSM;
      width: 50vw;
      background-image: url(../img/background.png);
    }
    .gnav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      > li {
        > a {
          text-decoration: none;
          display: flex;
          padding: 1rem 2rem;
          font-weight: bold;
        }
      }
    }
    .sns {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      padding: 1rem 2rem;
      a {
        img {
        }
      }
    }
  }
  &.bk {
    .toggleMenuBtn {
      .bar,
      &::before,
      &::after {
        background-color: $body-color;
      }
    }
    .menu {
      color: $body-color;
    }
  }
}
