#eventsummary {
  .midashi1 {
  }
  .eventText {
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }
  .row {
    .col {
      .eventSingle {
        .eventlink {
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          color: $body-color;
          transition: $transition-base;
          @include media-breakpoint-up(sm) {
            flex-direction: column;
            gap: 20px;
          }
          .img {
            border: 3px solid $white;
            flex-basis: 45%;
            position: relative;
            padding-top: 45%;
            @include media-breakpoint-up(sm) {
              width: 100%;
              flex-basis: auto;
              padding-top: 100%;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .eventCont {
            flex: 1;
            .eventHeader {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 5px;
              margin-bottom: 10px;
              @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              }
              .eventtitle {
                font-size: 13px;
                font-weight: bold;
                margin: 0;
                @include media-breakpoint-up(sm) {
                  font-size: 20px;
                }
              }
              .uketsuke {
                display: flex;
                background-color: #ff6939;
                color: $white;
                padding: 5px 10px;
                font-size: 10px;
                font-weight: bold;
                margin: 0;
                white-space: nowrap;
              }
            }
            .eventArea {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              border-top: solid 1px #7c7b69;
              font-size: 12px;
              margin: 0;
              @include media-breakpoint-up(sm) {
                font-size: 15px;
              }
              dt {
                width: 40%;
                font-weight: bold;
                border-bottom: solid 1px #7c7b69;
                padding: 5px 5px 5px 0;
                @include media-breakpoint-up(sm) {
                  width: 30%;
                  padding: 8px 15px 8px 0;
                }
              }
              dd {
                width: 60%;
                border-bottom: solid 1px #7c7b69;
                padding: 5px 0 5px 5px;
                margin: 0;
                @include media-breakpoint-up(sm) {
                  width: 70%;
                  padding: 8px 0 8px 15px;
                }
              }
            }
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .moreBtn {
  }
}
