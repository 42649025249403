#aboutArea {
  background-color: $white;
  .aboutGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-left: 6vw;
    margin-right: 6vw;
    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 50px;
      margin-left: 10vw;
      margin-right: 0;
    }
    .img {
      @include media-breakpoint-up(sm) {
        flex-basis: 55%;
      }
      img {
        width: 100%;
      }
    }
    .text {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      .midashi1 {
      }
      h3 {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
          font-size: 40px;
          margin-bottom: 50px;
        }
      }
      .aboutText {
        font-weight: bold;
        margin-bottom: 50px;
      }
      .moreBtn {
      }
    }
  }
}
