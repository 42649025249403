#voicesummary {
  .midashi1 {
  }
  .voiceText {
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }
  .row {
    .col {
      .voiceSingle {
        background-color: $white;
        padding: 20px;
        @include media-breakpoint-up(sm) {
          padding: 30px;
        }
        .voicelink {
          text-decoration: none;
          color: $body-color;
          transition: $transition-base;
          .img {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
            &::before {
              content: "";
              width: 100%;
              padding-top: calc(3 / 4 * 100%); //高さの比率 ÷ 幅の比率 × 100
              display: block;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .voiceSingleTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            .number {
              margin: 0;
            }
            .name {
              margin: 0;
            }
          }
          .voiceCont {
            margin: 0;
            font-weight: bold;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .moreBtn {
  }
}
