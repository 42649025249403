#eventsingle {
  .event-container {
    padding: 30px 6vw 80px;
    @include media-breakpoint-up(lg) {
      padding: 60px 20vw 120px;
    }
    .midashi1 {
    }
    .uketsuke {
      display: inline-block;
      background-color: #ff6939;
      color: $white;
      padding: 5px 10px;
      margin-bottom: 10px;
      font-size: 13px;
    }
    .eventtitle {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .mainImg {
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
      }
      img {
      }
    }
    .eventArea {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 60px;
      }
      dt {
        width: 30%;
        font-weight: bold;
        border-bottom: solid 1px #7c7b69;
        padding: 10px 15px;
        @include media-breakpoint-up(sm) {
          width: 25%;
          padding: 20px 15px;
        }
        &:last-of-type {
          border: none;
          width: 100%;
        }
      }
      dd {
        width: 70%;
        border-bottom: solid 1px #7c7b69;
        padding: 10px 15px;
        margin: 0;
        @include media-breakpoint-up(sm) {
          width: 75%;
          padding: 20px 15px;
        }
        div {
          margin-bottom: 20px;
        }
        .eventImg {
          max-width: 100%;
          img {
            width: 100%;
          }
        }
        &:last-of-type {
          border: none;
          width: 100%;
          padding: 0 15px;
        }
      }
    }
    .eventBtn {
      text-decoration: none;
      background-color: $primary;
      color: #fff;
      width: 250px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 50px;
      transition: $transition-base;
      &:hover {
        background-color: $body-color;
      }
    }
  }
  .backBtn {
  }
}
