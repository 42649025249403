.pagination {
  margin: 40px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-links .page-numbers {
  display: inline-block;
  margin-right: 20px;
  padding: 13px 20px;
  color: $body-color;
  text-decoration: none;
  transition: $transition-base;
  &:hover {
    background: $body-color;
    color: $white;
  }
}

@media screen and (max-width: 768px) {
  .nav-links .page-numbers {
    margin-right: 10px;
    padding: 13px 20px;
  }
}

.nav-links .current {
  padding: 13px 20px;
  background: #C65840;
  color: $white;
}

@media screen and (max-width: 768px) {
  .nav-links .current {
    padding: 13px 20px;
  }
}

.nav-links .prev {
  padding: 13px 20px;
  background: transparent;
  color: $body-color;
  border: solid 1px $body-color;
  transition: $transition-base;
  &:hover {
    background: $body-color;
    color: $white;
  }
}

@media screen and (max-width: 768px) {
  .nav-links .prev {
    padding: 8px 15px;
  }
}

.nav-links .next {
  padding: 13px 20px;
  background: transparent;
  color: $body-color;
  margin: 0;
  border: solid 1px $body-color;
  transition: $transition-base;
  &:hover {
    background: $body-color;
    color: $white;
  }
}

@media screen and (max-width: 768px) {
  .nav-links .next {
    padding: 8px 15px;
  }
}

.nav-links .dots {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}
