#serviceArea {
  .serviceGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: center;
      gap: 50px;
    }
    .serviceleft {
      font-weight: bold;
      .midashi1 {
      }
      .serviceleftText {
        line-height: 1.5;
      }
    }
    .serviceright {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
      .areaGroup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 50px;
        }
        .areaTitle {
          flex-basis: 22%;
          margin: 0;
        }
        .areaCont {
          flex: 1;
          margin: 0;
        }
      }
    }
  }
}
