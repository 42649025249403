#blogArea {
  border-bottom: solid 1px #7c7b69;
  .midashi1 {
  }
  .row {
    .col {
      .blogSingle {
        background-color: $white;
        padding: 20px;
        @include media-breakpoint-up(sm) {
          padding: 30px;
          height: 100%;
        }
        .bloglink {
          text-decoration: none;
          color: $body-color;
          transition: $transition-base;
          .img {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
            &::before {
              content: "";
              width: 100%;
              padding-top: 74%; //高さの比率 ÷ 幅の比率 × 100
              display: block;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .date {
            color: #7c7b69;
            margin-bottom: 5px;
          }
          .blogTitle {
            margin-bottom: 20px;
            font-weight: bold;
          }
          .blogText {
            p {
              margin: 0;
            }
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .moreBtn {
  }
}
