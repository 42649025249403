#voicesingle {
  border-bottom: solid 1px #7c7b69;
  .midashi1 {
  }
  .voiceHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      flex-direction: row-reverse;
      align-items: center;
      gap: 0;
      margin-bottom: 100px;
    }
    .img {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
      img {
      }
    }
    .voiceWhite {
      padding: 30px;
      background-color: rgba($color: $white, $alpha: 0.5);
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        flex-basis: 40%;
        margin-right: -50px;
        z-index: 2;
      }
      @include media-breakpoint-up(lg) {
        flex-basis: 30%;
      }
      .number {
        display: inline-block;
        font-family: Capitolium2;
        color: $white;
        font-size: 20px;
        padding: 5px 10px;
        margin-bottom: 5px;
        background-color: rgba($color: #214b3d, $alpha: 0.7);
        @include media-breakpoint-up(sm) {
          font-size: 32px;
          padding: 8px;
          margin-bottom: 10px;
        }
      }
      .name {
        margin-bottom: 10px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 30px;
        }
      }
      .title {
        margin: 0;
        font-size: 20px;
      }
    }
  }
  .voiceBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      gap: 100px;
    }
    .digest {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 70px;
      }
      .img {
        @include media-breakpoint-up(sm) {
          flex-basis: 40%;
        }
        img {
        }
      }
      .cont {
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
        .title {
          color: #c65840;
          font-size: 20px;
          margin-bottom: 30px;
        }
        .text {
          margin-bottom: 1em;
          &:last-child {
            margin: 0;
          }
        }
      }
      &:nth-child(even) {
        @include media-breakpoint-up(sm) {
          flex-direction: row-reverse;
        }
      }
    }
  }
  .backBtn {
  }
}
