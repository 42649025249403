#faqArea {
  border-bottom: solid 1px #7c7b69;
  .faqGroup {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      gap: 50px;
    }
    .faqleft {
      @include media-breakpoint-up(sm) {
        flex-basis: 40%;
      }
      .midashi1 {
      }
      .faqText {
        font-weight: bold;
        margin-bottom: 50px;
      }
    }
    .faqright {
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
    }
  }
}
